import "./App.scss";
import { useEffect, useState } from "react";
import vidaaxtvoe from "./images/tvoexvidaa.png";

import Success from "./components/Success";
import NotHisense from "./components/NotHisense";
import UnableToInstall from "./components/UnableToInstall";
import FooterContent from "./components/FooterContent";
import DownloadButton from "./components/DownloadButton";

function App() {
	const [downloadStatus, setDownloadStatus] = useState(null);

	useEffect(() => {
		if (typeof window.Hisense_GetCountryCode === "function") {
			if (typeof Hisense_installApp === "function") {
				setDownloadStatus("Ready");
			} else {
				setDownloadStatus("Impossible");
			}
		} else {
			setDownloadStatus("NotHisense");
		}
	}, []);

	const onClick = () => {
		const preview = "https://app.1390760-cu92735.tw1.ru/img/touch-logo.jpg";

		if (window?.Hisense_installApp) {
			window?.Hisense_installApp(
				"TVOЁ_debug",
				"TVOЁ",
				preview,
				preview,
				preview,
				"https://app.tvoe.live?device=smart-tv&installed=vidaa",
				"store",
				function () {
					setDownloadStatus("Success");
					// Тригерится когда реально обновляет файловую систему
				}
			);
			setDownloadStatus("Success");
		}
	};

	const downloadState = {
		Ready: <DownloadButton onClick={onClick} />,
		Success: <Success />,
		NotHisense: <UnableToInstall />,
		Impossible: <NotHisense />,
	};

	return (
		<div className="flex flex-col h-screen">
			<header className="flex justify-center items-center my-6 h-20">
				<img src={vidaaxtvoe} alt={"TVOЁ"} width={220} />
			</header>

			<div className="flex flex-col justify-center items-center gap-4 h-full">
				<div className="flex justify-center items-center">
					{downloadState[downloadStatus]}
				</div>
			</div>

			<FooterContent />
		</div>
	);
}

export default App;
